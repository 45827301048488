import React from "react";
import {
  CreditCardIcon,
  RefreshIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  XCircleIcon,
} from "@heroicons/react/solid";

import Header from "../components/sections/Header";
import ServicesSection from "../components/sections/ServicesSection";
import ServiceItem from "../components/elements/ServiceItem";
import FeaturesSection from "../components/sections/FeaturesSection";
import FeatureItem from "../components/elements/FeatureItem";
import Footer from "../components/sections/Footer";
import PageTitle from "../components/elements/PageTitle";

const CompanySePage = () => (
  <div className="bg-white niramit-regular">
    <PageTitle pageTitle={"Axxtrans företag"} description={``} />
    <Header lang="se" />
    <ServicesSection>
      <ServiceItem
        title={""}
        description={"Axxtrans accessystem"}
        paragraphs={[
          `Att främja staden genom digitalisering av lokala pass: turistpass för besökare och kultur- eller fritidskort för invånarna`,
          `Att skapa ett ekonomiskt och socialt nätverk mellan slutanvändare och lokala tjänsteleverantörer för att förbättra hanteringen av kundrelationer`,
          `Att öka antalet besök på kultur- och fritidsplatser. Att utveckla konsumtionen av lokala tjänster`,
        ]}
        imgName={"axxpay-image2.png"}
      />
      <ServiceItem
        title={""}
        description={"SoftPos"}
        paragraphs={[
          `Det är lättare än du tror. Efter nedladdningen kan du vara igång på nolltid. På vilken Android-enhet som helst med NFC är du snart redo att ta emot betalningar med vår Axxtrans-app. Från kreditkort och plånböcker till chips och smartklockor. För varje transaktion har vi dig täckt.`,
        ]}
        imgName={"axxpay-image3.png"}
      />
      <ServiceItem
        title={""}
        description={"Gör din enhet till en betalterminal."}
        paragraphs={[
          `Med Axxtrans kan din Android-baserade enhet bli en betalterminal (kontrollera kraven). Vår teknik är perfekt för dig. På några minuter kan du ställa in trådlösa onlinebetalningar på din föredragna enhet och enkelt integrera den i ditt företag.`,
        ]}
        imgName={"axxpay-main-image.png"}
      />
    </ServicesSection>
    <FeaturesSection title="We built our business on customer service">
      <FeatureItem
        title="Ta betalningar var som helst"
        description="Förvandlar de flesta Android 8+ enheter med NFC till betalterminaler som accepterar Visa, Mastercard även Apple Pay, Google Pay och Samsung Pay."
        Icon={CreditCardIcon}
      />
      <FeatureItem
        title="Avbryt betalning och återbetalning"
        description="Blev det fel? Inget problem! Axxtrans stöder annullering av betalning eller återbetalning till din kunds kort."
        Icon={XCircleIcon}
      />
      <FeatureItem
        title="Med eller utan PIN"
        description="Få dina kunder att känna sig säkra med en certifierad integrerad PIN-kodsmodul så att du kan ta betalningar av  valfritt belopp. En verkligt mobil betalterminal som du kan ta med dig överallt."
        Icon={ShoppingBagIcon}
      />
      <FeatureItem
        title="Fristående eller integrerad"
        description="Har du redan ett lojalitetsprogram, löpande rabatter eller andra betalsystem installerade? Genom Axxtrans API integrerar du sömlöst dina befintliga lösningar. Du kan även använda Axxtrans betalapp fristående. Allt enligt dina behov."
        Icon={RefreshIcon}
      />
      <FeatureItem
        title="Säkerhet"
        description="Lösningen är certifierad enligt EMV och PCI security standard."
        Icon={ShieldCheckIcon}
      />
    </FeaturesSection>
    <Footer lang="se" />
  </div>
);

export default CompanySePage;
